<template>
  <div class="background">
    <div style="position: absolute; right: 30px; top: 20px;">
      <el-dropdown @command="handleCommand" v-if="user">
        <span class="el-dropdown-link" style="cursor: pointer;">
          {{ user.UserName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" style="width: 90px; text-align: center;">
          <el-dropdown-item command="a">登出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <h2 style="text-align: left; margin: 0;">選擇服務</h2>
    <hr/>
    <el-row style="padding: 20px 0;" :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="5" v-for="(service, index) in services" :key="index" style="margin-bottom: 20px;">
        <div v-if="service.visible" class="box1" @click="redirect(service.router, service.title)">
          <div style="font-weight: 500; margin-top: 7px; height: 60px;">
            <img :src="service.icon" width="40" style="margin-right: 8px;"/>
            <div style="display: inline-block; transform: translateY(-5px); font-size: 16px;">{{ service.title }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- EMC -->
    <el-dialog :visible.sync="emcDia" :title="pageTitle" :close-on-click-modal="false" width="550px">
      <div style="text-align: right;">
        <div v-if="page === 'select'" style="margin-bottom: 10px; text-align: left; padding-left: 5px;">
          <mu-select v-model="emcAccount" @change="checkAccount">
            <mu-option v-for="option in emcAccounts"
              :key="option.Auth"
              :label="option.AuthName"
              :value="JSON.stringify(option)">
            </mu-option>
          </mu-select>
          <div style="color: #f24">{{ notOpenText }}</div>
          <div v-if="!notOpenText">已開通帳號：{{ usage }}/{{ totalAccount }}</div>
        </div>
        <div v-else style="margin-bottom: 15px; text-align: left;">
          <mu-select @change="setDefaultConfig" v-if="accountType === 'multiple'" style="margin-top: 10px;" v-model="pageNum" label="選擇綁定品牌數量" full-width>
            <mu-option v-for="option in maxEnable()" :key="option + 'n'" :label="`${option}`" :value="option"></mu-option>
          </mu-select>
          <el-switch v-if="pageNum > 1"
            v-model="syncPoint"
            :active-value="true"
            :inactive-value="false"
            active-text="打通點數"
            inactive-text="不打通點數">
          </el-switch><br><br>
          <mu-carousel :cycle="false" :hide-indicators="true" :hide-controls="true" :active="nowActive">
            <mu-carousel-item v-for="(tab, index) in pageNum" :key="index + 't'">
              <div class="pageTab" v-if="config[index]">
                <mu-text-field v-model.trim="config[index].name" placeholder="例：EILIS" label="品牌名稱 *" full-width></mu-text-field>
                <mu-text-field v-model.trim="config[index].lineId" placeholder="例：182039838" label="綁定 LINE ID (選填)" full-width></mu-text-field>
                <mu-text-field v-model.trim="config[index].fbId" placeholder="例：2167082039838" label="綁定 FB ID (選填)" full-width></mu-text-field>
                <!-- <mu-select label="使用語言" v-model="config[index].lang" full-width>
                  <mu-option v-for="(option, index) in langs" :key="index + 'l'" :label="option.label" :value="option.value"></mu-option>
                </mu-select> -->
                <mu-text-field v-model.trim="config[index].brandColor" placeholder="例：#ffffff" label="品牌色系" full-width></mu-text-field>
                <div style="text-align: left;">
                  <el-color-picker v-model="config[index].brandColor"></el-color-picker>
                </div>
              </div>
            </mu-carousel-item>
          </mu-carousel>
          <div class="checkIcon" v-if="checkValues()">
            <i class="el-icon-success"></i>資料填寫完成
          </div>
          <div style="margin-top: -10px;">
            <el-row>
              <el-col :span="11">
                <div style="text-align: right;">
                  <el-button style="margin: 0 !important;" type="primary" icon="el-icon-arrow-left" circle v-if="nowActive !== 0"
                    @click="nowActive -= 1">
                  </el-button>
                  <el-button style="margin: 0 !important;" type="primary" icon="el-icon-arrow-left" circle disabled plain v-else></el-button>
                </div>
              </el-col>
              <el-col :span="2">
                <div style="text-align: center; font-size: 20px; line-height: 40px;">{{ nowActive + 1 }}</div>
              </el-col>
              <el-col :span="11">
                <div style="text-align: left;">
                  <el-button style="margin: 0 !important;" type="primary" icon="el-icon-arrow-right" circle v-if="nowActive !== pageNum - 1"
                    @click="nowActive += 1">
                  </el-button>
                  <el-button style="margin: 0 !important;" type="primary" icon="el-icon-arrow-right" circle disabled plain v-else></el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" style="text-align: right;">
          <!-- <el-button type="info" style="margin-right: 10px;" flat @click="emcDia = false">關閉</el-button> -->
          <el-button v-if="page === 'add'" type="primary" :disabled="!checkValues()" @click="checkData">確認開通</el-button>
          <el-button v-if="page === 'select'" type="primary" @click="page ='add'" :disabled="totalAccount === usage">新增帳號</el-button>
          <el-button v-if="page === 'select'" type="warning" :disabled="!emcAccount || notOpenText !== ''" @click="toEMCAdmin">登入</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- LOGIN -->
    <mu-dialog :open="checkLogout" width="350">
      <div style="text-align: center;">
        <mu-icon value="account_circle" size="50"></mu-icon>
      </div>
      <div v-if="user" style="text-align: center; margin: 10px; margin-bottom: 15px;">確定要登出嗎?</div>
      <el-row :gutter="5">
        <el-col :span="12">
          <div class="checkButton" @click="checkLogout = false;">返回</div>
        </el-col>
        <el-col :span="12">
          <div class="checkButton" @click="logout">確認</div>
        </el-col>
      </el-row>
      <br>
    </mu-dialog>
    <!-- PROCESS -->
    <mu-dialog :open="processDia" width="550">
      {{ processText }}
      <mu-button slot="actions" color="primary" :disabled="!processDone" @click="reloadPage">關閉</mu-button>
    </mu-dialog>
    <!-- 浮水印 -->
    <div style="position: fixed; bottom: 10px; left: 10px; text-align: left;">
      <img src="logo_red.png" width="120"/>
      <div style="padding-left: 8px; font-size: 13px; color: #444;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

const defaultContent = {
  accountId: '',
  fbId: '',
  lineId: '',
  name: '',
  lang: 'zh',
  brandColor: '#000'
};

export default {
  data() {
    return {
      services: [
        {
          icon: 'com.png',
          title: 'EILIS 社群數位會員卡',
          visible: true
        },
        {
          icon: 'louisa.png',
          title: '路易莎總部後台',
          router: 'louisa-admin/',
          visible: false
        }
      ],
      config: [
        {
          accountId: '',
          fbId: '',
          lineId: '',
          name: '',
          lang: 'zh',
          brandColor: '#000'
        }
      ],
      syncPoint: true,
      platform: 'FB',
      platforms: ['FB', 'LINE'],
      langs: [
        { label: '中文', 'value': 'zh' },
        { label: '英文', 'value': 'en' }
      ],
      showProgress: false,
      notOpenText: '',
      accountType: 'multiple',
      nowActive: 0,
      checkLogout: false,
      visible: false,
      emcDia: false,
      open: false,
      processDia: false,
      processDone: false,
      processText: '',
      pageNum: 1,
      page: 'select',
      emcAccount: '',
      syncPoint: false,
      totalAccount: 0,
      usage: 0,
      emcAccounts: []
    };
  },
  computed: {
    ...emc.mapGetters({
      user: 'user'
    }),
    ...mapGetters({
      aesCode: 'aesCode'
    }),
    pageTitle() {
      if (this.page === 'select') {
        return '請選擇帳號';
      }
      else {
        return '新增帳號';
      }
    },
    showHint() {
      if (this.accountType === 'single') {
        return '若選擇開通單一帳號，則無法再綁定其他帳號'
      }
      return '綁定多帳號後，會員個人資料以及點數將會打通。';
    }
  },
  mounted() {
    const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
    localStorage.setItem('dayungsPage', '');
    if (localStorage.getItem('uid')) {
      this.getUserDetail().then(() => {
        loading.close();
        if (this.user) {
          if (this.user.Service && this.user.Service.client && this.user.Service.client.louisa) {
            this.services[1].visible = true;
          }
          if (this.user.Service && this.user.Service.client && this.user.Service.client.emclite) {
            this.emcAccounts = Object.values(this.user.Service.client.emclite);
          }
        }
        else {
          localStorage.clear();
          this.$router.push('/');
        }
        this.services = this.services.filter(el => el.visible);
      })
      this.getAccountInfo({ uid: localStorage.getItem('uid') })
        .then((res) => {
          if (res.data) {
            this.totalAccount = res.data.limit;
            this.usage = res.data.usage;
            if (this.totalAccount === 0) {
              this.notOpenText = '你目前沒有帳號額度，請先前往付費開通此功能。';
            }
            else {
              this.notOpenText = '';
            }
          }
        });
    }
    else {
      localStorage.clear();
      this.$router.push('/');
      loading.close();
    }
  },
  methods: {
    ...emc.mapActions({
      getUserDetail: 'getUserDetail',
      setEMCAdmin: 'setEMCAdmin',
      getAccountInfo: 'getAccountInfo',
      enableAccount: 'enableAccount',
      enableClient: 'enableClient'
    }),
    ...mapActions({
      getEncryptCode: 'getEncryptCode'
    }),
    uploadUrl(auth) {
      if (auth) {
        return `http://localhost:3000/api/upload/${auth}`;
      }
      else {
        return `http://localhost:3000/api/upload/eilis`;
      }
    },
    getFullDate(time) {
      const now = new Date(time).toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
      // 取得個別值
      let year = '';
      let month = '';
      let date = '';
      let usableMonth = '';
      let usableDate = '';
      // 取得對應時間
      year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
      month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
      date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
      // 修正時間格式
      usableMonth = parseInt(month) < 10 ? `0${month}` : month;
      usableDate = parseInt(date) < 10 ? `0${date}` : date;
      // 產出時間
      return `${year}-${usableMonth}-${usableDate}`;
    },
    checkAccount(val) {
      const pageData = JSON.parse(val);
      if (pageData.status === 'disabled') {
        this.notOpenText = `這個帳號已於 ${this.getFullDate(pageData.disabledTime)} 被停用。`;
      }
      else {
        this.notOpenText = '';
      }
    },
    handleCommand() {
      this.checkLogout = true;
    },
    reloadPage() {
      // 重整頁面
      window.location.reload();
    },
    maxEnable() {
      let list = [];
      const maxLimit = this.totalAccount - this.usage;
      for (let i = 0; i < maxLimit; i += 1) {
        list.push(i + 1);
      }
      return list;
    },
    checkData() {
      this.emcDia = false;
      this.processDone = false;
      this.processDia = true;
      this.processText = '設定品牌資料中...';
      // 匯入 accountId
      const random = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
      for (let i = 0; i < this.config.length; i += 1) {
        let code = '';
        for (let i = 0; i < 15; i += 1) {
          const position = Math.floor(Math.random() * 10);
          code += random[position];
        }
        this.config[i].accountId = code;
      }
      const postData = {
        uid: localStorage.getItem('uid'),
        email: this.user.Email,
        name: this.user.UserName,
        lists: this.config
      };
      const postData2 = {
        clients: this.config,
        sync: this.syncPoint
      };
      let promises = [];
      promises.push(this.enableAccount(postData));
      promises.push(this.enableClient(postData2));
      Promise.all(promises).then(() => {
        this.processDone = true;
        this.processText = '設定完成';
      })
    },
    setDefaultConfig(val) {
      let emptyArr = [];
      for (let i = 0; i < val; i += 1) {
        emptyArr.push(JSON.parse(JSON.stringify(defaultContent)));
      }
      this.config = emptyArr;
    },
    checkValues() {
      let nameArray = [];
      for (let i = 0; i < this.config.length; i += 1) {
        if (this.config[i].name && this.config[i].brandColor) {
          if (this.config[i].lineId || this.config[i].fbId) {
            nameArray.push(true);
          }
          else {
            nameArray.push(false);
          }
        }
        else {
          nameArray.push(false);
        }
      }
      if (nameArray.filter(el => !el).length === 0) {
        return true;
      }
      else {
        return false;
      }
    },
    getPageNum(val) {
      this.nowActive = 0;
      this.pageNum = 1;
      this.config = [{
        accountId: '',
        fbId: '',
        lineId: '',
        name: '',
        lang: 'zh',
        brandColor: '#000'
      }];
    },
    showErrorText(val) {
      if (val) {
        return ''
      }
      return '此為必填選項';
    },
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },
    toEMCAdmin() {
      this.getEncryptCode(this.emcAccount);
      localStorage.setItem('emcClient', this.aesCode);
      // console.log(this.emcAccount);
      this.$router.push('/emc-admin');
    },
    redirect(val, title) {
      if (val) {
        this.$router.push(val);
      }
      else {
        if (title === '今日實聯制管理後台') {
          // this.getEncryptCode(JSON.stringify({
          //   AuthName: '今日實聯制',
          //   Auth: '1656001889',
          //   Service: 'LINE'
          // }));
          // localStorage.setItem('emcClient', this.aesCode);
          // this.$router.push('/covid-admin');
        }
        else {
          this.page = 'select';
          this.emcDia = true;
        }
      }
    },
  }
}
</script>

<style scoped>
  .background {
    background: 100% #fff;
    height: 100vh;
    overflow: auto;
    padding: 70px 70px;
  }
  .colorBox {
    border: 1px solid #eee;
    width: 80px;
    height: 35px;
    border-radius: 5px;
  }
  .appbar {
    width: 100%;
    position: relative;
    color:#444444;
    box-shadow: none;
  }
  .checkIcon {
    position: absolute;
    color: #2fcc56;
  }
  .hintText {
    font-size: 15px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #777;
  }
  .pageTab {
    border: 1px solid #b9bdba;
    border-radius: 5px;
    height: calc(100vh - 420px) !important;
    max-height: 435px !important;
    overflow: auto;
    padding: 15px;
    text-align: center;
    font-size: 20px;
  }
  .checkButton:hover {
    background-color: #555;
    color: #fff;
  }
  .checkButton {
    text-align: center;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .dot {
    display: inline-block;
    background-color: #00c300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
    transform: translateY(-2px);
  }
  .box1 {
    text-align: left;
    box-shadow: 2px 2px 12px -1px rgba(20% ,20% ,40% ,0.5);
    padding: 10px 15px;
    height: 100px;
    /* max-width: 300px; */
    background-color: #fff;
    border-radius: 10px;
    transition: 0.2s;
    position: relative;
    cursor: pointer;
  }
  .box1:hover {
    transform: scale(1.05);
  }
</style>